.reporting-container {
    display: grid;
    grid-template-columns: 300px auto;
    .sidebar {
        border-right: 1px solid var(--ui-border);
        background-color: var(--ui-table);
        padding: 16px 10px;
        a {
            text-decoration: none;
        }
        > a {
            padding: 12px 16px;
            border-radius: 5px;
            font-weight: 600;
            &.active {
                background-color: white;
                color: var(--ui-container);
                border-radius: 5px 5px 0 0;
            }
            &:hover:not(.active){
                transition: .25s;
                background-color: var(--ui-border);
            }
        }
        .navigation-options {
            background-color: white;
            border-radius: 0 0 5px 5px;
            > a {
                padding: 12px 16px;
                color: var(--ui-container);
                opacity: .7;
                &.active {
                    background-color: rgb(220, 220, 220);
                }
                &:hover:not(.active){
                    transition: .25s;
                    background-color: rgb(232, 232, 232);
                }
                &:last-child:hover:not(.active){
                    border-radius: 0 0 5px 5px;
                }
                border-top: 1px solid rgb(179, 179, 179);
            }
        }
        >a.active+.navigation-options {
            display: block;
        }
        >a:not(.active)+.navigation-options {
            display: none;
        }
    }
}