@import "categories/variables.scss";
@import "categories/typography.scss";
@import "categories/form.scss";
@import "categories/animations.scss";
@import "categories/colouring.scss";
@import "categories/layouts.scss";
@import "categories/table.scss";

@import "components/card.scss";
@import "components/table.scss";
@import "components/modal.scss";
@import "components/header.scss";
@import "components/status-page.scss";

@import "views/sign-in.scss";
@import "views/reporting.scss";

body {
    margin: 0;
    background-color: var(--ui-bg);
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.portal-container {
    display: grid;
    grid-template-rows: min-content auto;
    height: 100vh;
    .route-container {
        display: grid;
        grid-template-columns: 1fr minmax(0, 1100px) 1fr;
        .col-start-2 {
            display: grid;
        }
    }
}


.recharts-wrapper {
    font-family: $font-primary;
    .recharts-legend-item-text {
      color: var(--ui-text);
    }
    .recharts-default-tooltip {
      background-color:var(--ui-border)!important;
      border:1px solid var(--ui-text-lighter)!important;
      border-radius: 4px;
       * {
      color:var(--ui-text)!important;
      }
    }
    .recharts-cartesian-axis-tick {
      font-size: 14px;
    }
  }
  