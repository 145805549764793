.sign-in-container {
    display: grid;
    align-content: center;
    justify-items: center;
    min-height: 100vh;
    .ui-card {
        padding: 40px 30px;
        width: 100%;
        max-width: 550px;
        img {
            width: 180px;
        }
        position: relative;
        z-index: 2;
    }
    #tsparticles {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}