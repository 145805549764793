@font-face {
  font-family: "GT Walsheim";
  src: url("../../assets/fonts/gt-walsheim-bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim";
  src: url("../../assets/fonts/gt-walsheim-regular.ttf");
  font-weight: normal;
  font-style: normal;
}

$colours: (
  pink: #D4215A,
  aqua: #60C1C9,
  blue:#337DAF,
  green: #79B54C,
  orange:#EF9E39
);

$ui: (
  text-lighter: #698d94,
  border: #153a3f,
  text: #ffffff,
  bg: #09171a,
  container: #0d2226,
  table: #0b1b1f
);

:root {
  @each $name, $colour in $colours {
    --theme-#{$name}: #{$colour};
  }
  @each $name, $colour in $ui {
    --ui-#{$name}: #{$colour};
  }
}

$font-primary:"GT Walsheim", sans-serif;
