
.col {
  display: grid;
  @for $i from 1 to 13 {
    &-#{$i} {
      display: grid;
      grid-template-columns: repeat($i, 1fr);

      @media only screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
      }

      &-min {
        display: grid;
        grid-template-columns: repeat($i, min-content);
      }

      &-max {
        display: grid;
        grid-template-columns: repeat($i, max-content);
      }
    }
    &-start-#{$i} {
      grid-column-start: $i;
    }
    &-end-#{$i} {
      grid-column-end: $i + 1;
    }
    &-start-md-#{$i} {
      grid-column-start: $i;

      @media only screen and (max-width: 750px) {
        grid-column-start: 1;
      }
    }
    &-end-md-#{$i} {
      grid-column-end: $i + 1;

      @media only screen and (max-width: 750px) {
        grid-column-end: 1;
      }
    }
  }
}

.grid {
  display: grid;
  &-center {
    &-top {
      display: grid;
      align-items: start;
      justify-content: center;
    }
    &-center {
      display: grid;
      align-items: center;
      justify-items: center;
    }
    &-bottom {
      display: grid;
      align-items: end;
      justify-content: center;
    }
  }
  &-left {
    &-top {
      display: grid;
      align-items: start;
      justify-content: left;
    }
    &-center {
      display: grid;
      align-items: center;
      justify-content: left;
    }
    &-bottom {
      display: grid;
      align-items: end;
      justify-content: left;
    }
  }
  &-right {
    &-top {
      display: grid;
      align-items: start;
      justify-items: right;
    }
    &-center {
      display: grid;
      align-items: center;
      justify-items: right;
    }
    &-bottom {
      display: grid;
      align-items: end;
      justify-items: right;
    }
  }
}

.gap {
  @for $i from 1 to 100 {
    &-#{$i} {
      gap: 0px + $i;
    }
  }
}


.padding {
  @for $i from 0 to 100 {
      &-#{$i} { padding: 0px + $i!important };
      &-top-#{$i} { padding-top: 0px + $i!important };
      &-bottom-#{$i} { padding-bottom: 0px + $i!important };
      &-left-#{$i} { padding-left: 0px + $i!important };
      &-right-#{$i} { padding-right: 0px + $i!important };
  }
}

.margin {
  @for $i from 0 to 100 {
      &-#{$i} { margin: 0px + $i!important };
      &-top-#{$i} { margin-top: 0px + $i!important };
      &-bottom-#{$i} { margin-bottom: 0px + $i!important };
      &-left-#{$i} { margin-left: 0px + $i!important };
      &-right-#{$i} { margin-right: 0px + $i!important };
  }
}

.desktop-only {
  @media only screen and (max-width: 600px){
    display: none;
  }
}

.container {
  display: grid;
  &-form {
    @extend .container;
    grid-template-columns: minmax(5%, 1fr) minmax(0, 500px) minmax(5%, 1fr);
  }
  &-small {
    @extend .container;
    grid-template-columns: minmax(5%, 1fr) minmax(0, 700px) minmax(5%, 1fr);
  }
  &-medium {
    @extend .container;
    grid-template-columns: minmax(5%, 1fr) minmax(0, 1000px) minmax(5%, 1fr);
  }
  &-large {
    @extend .container;
    grid-template-columns: minmax(7.5%, 1fr) minmax(0, 1400px) minmax(7.5%, 1fr);
  }
  &-nav {
    @extend .container;
    grid-template-columns: minmax(3.5%, 1fr) minmax(0, 1350px) minmax(3.5%, 1fr);
  }
  &-layout {
    @extend .container;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2560px) minmax(0, 1fr);
    .col-start-2 {
      overflow-x: hidden;
      position: relative;
    }
  }
}