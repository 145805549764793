.ui {
  &-table {
    border: 1px solid var(--ui-border);
    font-family: $font-primary;
    color: map-get($colours, grey);
    width: 100%;
    border-collapse: collapse;
    @media only screen and (max-width: 1000px){
      font-size: 14px;
    }
    @media only screen and (max-width: 600px){
      font-size: 12px;
    }
    tbody {
    td {
      border-bottom: 1px solid var(--ui-border);
      padding: 16px;
      @media only screen and (max-width: 1000px){
        padding: 12px;
      }
      &:not(:first-child) {
        font-weight: 500;
      }
    }
  }
  thead {
    td {
      opacity: .5;
      border-bottom: 1px solid var(--ui-border);
      padding: 16px;
      @media only screen and (max-width: 1000px){
        padding: 12px;
      }
    }
  }
  }
}