.header {
    background-color: var(--ui-container);
    padding-left: 14px;
    display: grid;
    grid-template-columns: min-content auto min-content;
    align-items: center;
    border-bottom: 1px solid var(--ui-border);
    img {
        height: 36px;
        margin-right: 0;
    }
    .navigation {
        display: flex;
        padding: 10px 0px;
        a {
            padding: 10px 14px;
            border-radius: 4px;
            text-decoration: none;
            &:not(:last-child){
                margin-right: 10px;
            }
            &.active {
                background-color: var(--ui-text);
                color: var(--ui-container);
            }
            &:hover:not(.active){
                transition: .25s;
                background-color: var(--ui-border);
            }
        }
        justify-content: center;
    }
    .user-options {
        display: flex;
        justify-self: flex-end;
        a {
            padding: 20px;
            border-left: 1px solid var(--ui-border);
            text-decoration: none;
            p {
                font-size: 16px;
                display: flex;
                align-items: center;
                white-space: nowrap;
                margin: 0;
                i {
                    // font-size: 20px;
                    padding-left: 12px;
                }
            }
        }
    }
}